<template>
    <div class="__trend_bg_seperation">
        <div class="h-full text-center flex flex flex-col items-center">
            <!-- Create the components here -->

            <D_Exponent class="mt-5" v-if="selectedReport == 'D-EXPONENT'" :widgetHeight="height-30"
                :widgetWidth="width - 80" :widgetId="widgetId" :displayId="displayId" :id="id"
                @dexponentEmits='onDoubleClickOfDexponent'
                :axisLabelColor="rect.DExponent?.axisLabelColor" :graphScaleColor="rect.DExponent?.graphScaleColor"
                :gridColor="rect.DExponent?.gridColor" :xAxisLabelSize="rect.DExponent?.xAxisLabelSize"
                :yAxisLabelSize="rect.DExponent?.yAxisLabelSize"
                :primaryYaxisFullName="rect.DExponent?.primaryYaxisFullName"
                :primaryYaxisUnit="rect.DExponent?.primaryYaxisUnit"
                :secondaryYaxisFullName="rect.DExponent?.secondaryYaxisFullName"
                :secondryYaxisUnit="rect.DExponent?.secondryYaxisUnit" :isSecondaryAxis="rect.DExponent?.isSecondaryAxis"
                :secondaryAxisLabelColor="rect.DExponent?.secondaryAxisLabelColor"
                :graphSecondaryScaleColor="rect.DExponent?.graphSecondaryScaleColor"
                :yAxisSecondaryLabelSize="rect.DExponent?.yAxisSecondaryLabelSize" :isSlider="rect.DExponent?.isSlider"
                :primaryXaxisFullName="rect.DExponent?.primaryXaxisFullName" :scale = "rect.DExponent?.scale" />
            <MSEKPI class="mt-5" v-if="selectedReport == 'MSE'" :widgetHeight="height - 30"
                :widgetWidth="width - 80" :widgetId="widgetId" :displayId="displayId" :id="id"
                @dblclick='onDoubleClickOfDexponent' @dexponentEmits='onDoubleClickOfDexponent'
                :axisLabelColor="rect.MSE?.axisLabelColor" :graphScaleColor="rect.MSE?.graphScaleColor"
                :gridColor="rect.MSE?.gridColor" :xAxisLabelSize="rect.MSE?.xAxisLabelSize"
                :yAxisLabelSize="rect.MSE?.yAxisLabelSize"
                :primaryYaxisFullName="rect.MSE?.primaryYaxisFullName"
                :primaryYaxisUnit="rect.MSE?.primaryYaxisUnit" 
                :secondaryYaxisFullName="rect.MSE?.secondaryYaxisFullName"
                :secondryYaxisUnit="rect.MSE?.secondryYaxisUnit"  :secondaryYaxisUnitFactor="rect.MSE?.secondaryYaxisUnitFactor" :primaryYaxisUnitFactor="rect.MSE?.primaryYaxisUnitFactor" :isSecondaryAxis="rect.MSE?.isSecondaryAxis"
                :secondaryAxisLabelColor="rect.MSE?.sevondaryAxisLabelColor"
                :graphSecondaryScaleColor="rect.MSE?.graphSecondaryScaleColor"
                :yAxisSecondaryLabelSize="rect.MSE?.yAxisSecondaryLabelSize" :isSlider="rect.MSE?.isSlider"
                :primaryXaxisFullName="{name:'Depth', value:'dmea'}" :scale = "rect.MSE?.scale" :idx='idx' />

        </div>

    </div>
</template>

<script>
import { values } from 'pdf-lib'
import rect from '../../../store/modules/rect'
import D_Exponent from './D_Exponent/Dexponent.vue'
import MSEKPI from './MSE/Mse.vue'
export default {
    emits: ['emitMaindash'],

    data() {
        return {
            rect: {},
            idx: null
        }
    },
    methods: {
        mouse(){
            console.log('mouse event triggers')
        },
        onDoubleClickOfDexponent() {
            console.log('triggere the object')
            const newobject = { name: this.selectedReport }
            this.$emit('emitMaindash', this.values[0], { ...this.values[1], ...newobject })
        },
        async commonDispatcher(keyToDispatch, val) {
            setTimeout(() => {
                this.$store.dispatch("rect/swabSurgeRealTimedata", {
                    displayId: this.displayId,
                    widgetId: this.widgetId,
                    value: val,
                    field: keyToDispatch,
                });
            }, 300);
        }
    },
    async mounted() {
        this.tag1 = "tvd";
        this.tag2 = "dispew";

        console.log('mounted reportstrend')
        let allRects = this.$store.state.rect.rects;
        console.log(allRects, 'all rects')
        const rect = allRects.filter((each) => each.widgetId == this.widgetId && each.displayId == this.displayId && each.name == 'Reports' && each.selectedReport == this.selectedReport)
        this.rect = rect[0]
        console.log(this.displayId, this.widgetId, 'ids')
        console.log(rect[0], "from reportstrend")


    },


    props: {
        values: Array,
        selectedReport: {
            type: String,
            default: null,
        },
        height: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        displayIndex: {
            type: [String, Number],
            default: null
        },
        widgetId: [String],
        displayId: [String],
        id: [String, Number],

        cardBackground: {
            type: String,
            default: "var(navBar2Bg)",
        },
        widgetHeight: {
            type: Number,
            default: 200,
        },
        widgetWidth: {
            type: Number,
            default: 350,
        },

        displayId: String,
        id: String,

        widgetId: String,
        idx :  [String, Number]





    },
    components: {
        D_Exponent,
        MSEKPI
    },
    computed: {

        // graphScaleColor() {
        //     return this.rect.DExponent.graphScaleColor
        // },
        // gridColor() {
        //     return this.rect.DExponent.gridColor
        // },
        // graphBackgroundColor() {
        //     return this.rect.DExponent.graphBackgroundColor
        // },
        // xAxisLabelSize() {
        //     return this.rect.DExponent.xAxisLabelSize
        // },
        // yAxisLabelSize() {
        //     return this.rect.DExponent.yAxisLabelSize
        // },
        // secondaryAxisLabelColor() {
        //     return this.rect.DExponent.secondaryAxisLabelColor
        // },
        // graphSecondaryScaleColor() {
        //     return this.rect.DExponent.graphSecondaryScaleColor
        // },
        // yAxisSecondaryLabelSize() {
        //     return this.rect.DExponent.yAxisSecondaryLabelSize
        // },

    },


}
</script>

<style scoped>
.__trend_bg_seperation {
    /* border: solid 0.5px;
   border-color: dimgray; */
    background: var(--sidebarbg);
}
</style>