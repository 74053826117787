<template>
  <div class="flex">
    <!-- <canvas ref="cursorCanvas" class="cursor_can" :width="width" :height="height" @mousemove="crossHairMousemove"
      @mouseleave="crossHairMouseLeave" 
      @dblclick="$emit('onDoubleClickOfDexponent')"
      :style="{ 'margin-left': x + 'px', 'margin-top': y + 'px' }"></canvas>
    <canvas :ref="id" :width="totl_width" :height="totl_height"></canvas>

    <div v-if="tooltip" class="tooltip textColorClass"
      :style="{ left: tooltip.left + 'px', top: tooltip.top + 'px', color: 'var(--textColor)' }">
      <span class="text-size"> {{ tooltip.content }}</span>

    </div> -->
    <canvas ref="cursorCanvas" class="cursor_can" :width="width" :height="height" @mousemove="crossHairMousemove"
      @mouseleave="crossHairMouseLeave" @dblclick="$emit('onDoubleClickOfDexponent')"
      :style="{ 'margin-left': x + 'px', 'margin-top': y + 'px' }"></canvas>

    <canvas :ref="id" :width="totl_width" :height="totl_height"></canvas>
    <div v-if="tooltip" class="tooltip textColorClass mt-5" :style="{
      left: tooltip.left + 'px',
      top: tooltip.top + 'px',
      color: 'white',
    }">
      <span class="text-size"> {{ tooltip.content }}</span>
    </div>
  </div>
</template>
<script>
import { scaleLinear, zoom, select, scaleTime } from "d3";
import { type } from "os";
import moment from "moment-timezone";
// import moment from "moment/src/moment";
// import Worker from '../../../../mse_worker'
// import MyWorker from '../../../../myWorker.mse_worker.js';
// import mse_worker from '../../../../index'
import Worker from "worker-loader!./mse_worker";
let mse_worker = null;
export default {
  emits: ["xCoordsAfterZoom", "onDoubleClickOfDexponent"],
  name: "CanvasLineChart-D-Exponent",
  props: {
    time_zone: {
      type: String,
    },
    totl_width: {
      type: Number,
      required: false,
      default: 600,
    },
    totl_height: {
      type: Number,
      required: false,
      default: 300,
    },
    data_set: {
      type: Array,
      required: true,
    },
    data_set_secondary: {
      type: Array,
      required: true,
    },
    min_X: {
      type: Number,
      default: 0,
    },
    min_Y: {
      type: Number,
      default: 0,
    },
    min_Y_Secondary: {
      type: Number,
      default: 0,
    },
    max_X: {
      type: Number,
      default: 100,
    },
    max_Y: {
      type: Number,
      default: 100,
    },
    max_Y_Secondary: {
      type: Number,
      default: 100,
    },
    id: {
      type: String,
      default: "mycanvasId",
    },
    logType: {
      type: String,
      default: "time",
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    primaryAxixX: {
      type: Object,
      default: null,
    },
    primaryAxixY: {
      type: Object,
      default: null,
    },
    secondaryAxixY: {
      type: Object,
      default: null,
    },
    enableSecondaryYaxis: {
      type: Boolean,
      default: false,
    },
    primaryAxisLabelColor: {
      type: String,
      default: "",
    },
    secondaryAxisLabelColor: {
      type: String,
      default: "",
    },
    gridColor: {
      type: String,
      default: "",
    },
    primaryGrapgColor: {
      type: String,
      default: "",
    },
    secondaryGrapgColor: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSizeSecondary: {
      type: Number,
    },
    primaryYaxisUnit: {
      type: String,
      default: "",
    },
    secondryYaxisUnit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      minX: 0,
      minY: 0,
      minYsecondary: 0,
      maxX: 0,
      maxY: 0,
      maxYsecondary: 0,

      yAxisTitle: "Y-Title",
      secondaryYaxisTitle: "Y-Title",
      xAxisTitle: "X-Title",

      padding: 20,
      paddingFromRight: 40,
      tickSize: 6,
      pointRadius: 5,
      font: "10pt Calibri",
      fontHeight: 12,
      canvas: null,
      context: null,
      tooltip: null,
      mouseX: 0,
      isResetZoomBtnEnable: false,
      zoomed_X_Axis: null,
      zoomed_Y_Axis: null,
      zoomed_secondary_Y_Axis: null,
      cursorCanvas: null,
      cursorContext: null,
      width: 0,
      height: 0,
      mousedown: false,
      selectionStart: {
        x: 0,
        y: 0,
      },
      selectionEnd: {
        x: 0,
        y: 0,
      },
      isDragged: false,
      xCoord: 0,
      yCoord: 0,
      result: "",

      offscreen: null,
      refreshcanvas: 21,
    };
  },
  computed: {
    numXTicks() {
      return 10;
    },
    numYTicks() {
      return 10;
    },
    x() {
      return this.padding * 4;
    },
    y() {
      return this.padding;
    },
    widthOfCanvas() {
      return this.totl_width - this.x - this.padding * 2;
    },
    heightOfCanvas() {
      return this.totl_height - this.y - this.padding * 3 - this.fontHeight;
    },

    xScale() {
      if (this.logType == "time") {
        return scaleTime()
          .domain([this.min_X, this.max_X])
          .range([this.x, this.x + this.width]);
      } else {
        return scaleLinear()
          .domain([this.min_X, this.max_X])
          .range([this.x, this.x + this.width]);
      }
    },
    yScale() {
      return scaleLinear()
        .domain([this.min_Y, this.max_Y])
        .range([this.y, this.y + this.height]);
    },
    yScaleSecondary() {
      return scaleLinear()
        .domain([this.min_Y_Secondary, this.max_Y_Secondary])
        .range([this.y, this.y + this.height]);
    },
    axisColor() {
      return getComputedStyle(this.canvas).getPropertyValue("--textColor");
    },
  },
  methods: {
    async resetZoom() {
      this.zoomed_X_Axis = null;
      this.zoomed_Y_Axis = null; // Reset the zoom to the original scale
      this.zoomed_secondary_Y_Axis = null; // Reset the zoom to the original scale
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;
      if (this.enableSecondaryYaxis) {
        this.minYsecondary = this.min_Y_Secondary;
        this.maxYsecondary = this.max_Y_Secondary;
      }
      mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          AxisColor: this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
      });
      // this.$emit('xCoordsAfterZoom', this.minX, this.maxX);
    },
    //   // fetchData() {

    //   //   /* ... */
    //   //   mse_worker.postMessage('ANY MESSAGE YOU WANT').then(reply =>
    //   //     console.log(reply)
    //   //   )
    //   // },

    //   drawAxisX(context, tickData) {
    //     // Draw X axis line
    //     context.beginPath();
    //     context.moveTo(this.x, this.y + this.height);
    //     context.lineTo(this.x + this.width, this.y + this.height);
    //     context.strokeStyle = this.axisColor;
    //     context.lineWidth = 2;
    //     context.stroke();

    //     // Draw ticks and labels
    //     tickData.forEach(({ xPos, label }) => {
    //       // Draw tick mark
    //       context.beginPath();
    //       context.strokeStyle = this.axisColor;
    //       context.moveTo(xPos, this.y + this.height);
    //       context.lineTo(xPos, this.y + this.height + this.tickSize);
    //       context.stroke();

    //       // Draw tick label
    //       context.font = this.font;
    //       context.fillStyle = this.axisColor;
    //       context.textAlign = "center";
    //       context.textBaseline = "middle";
    //       context.fillText(label, xPos, this.y + this.height + this.padding);
    //     });

    //     // Draw axis label
    //     context.fillStyle = this.primaryAxisLabelColor || this.axisColor;
    //     context.font = this.xAxisLabelSize ? `${this.xAxisLabelSize}pt Calibri` : this.font;
    //     const labelText = this.logType || this.xAxisTitle;
    //     const textWidth = context.measureText(labelText).width;
    //     context.fillText(labelText, this.width / 2 + this.x - textWidth / 2, this.height + 65);
    //   },
    mouseDownHandler(event) {
      if (!this.enableZoom) return;
      const rect = this.cursorCanvas.getBoundingClientRect();
      this.selectionStart = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.mousedown = true;
    },
    mouseUpHandler() {
      if (!this.enableZoom) return;
      if (this.isDragged) {
        this.handleSelectionEnd();
      }

      this.mousedown = false;
      this.cursorContext.clearRect(
        0,
        0,
        this.cursorCanvas.width,
        this.cursorCanvas.height
      ); //clear canvas
      this.isDragged = false;
    },
    zoomed({ transform }) {
      console.log("zoom is calling");
      if (!this.enableZoom) return;
      // this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update scales with zoom
      const new_xScale = transform.rescaleX(this.xScale);
      const new_yScale = transform.rescaleY(this.yScale);
      const new_secondary_yScale = transform.rescaleY(this.yScaleSecondary);

      this.zoomed_X_Axis = new_xScale;
      this.zoomed_Y_Axis = new_yScale;
      this.zoomed_secondary_Y_Axis = new_secondary_yScale;

      // Update the scales
      let minX = new_xScale.domain()[0];
      let maxX = new_xScale.domain()[1];
      let minY = new_yScale.domain()[0];
      let maxY = new_yScale.domain()[1];
      let minYsecondary = new_secondary_yScale.domain()[0];
      let maxYsecondary = new_secondary_yScale.domain()[1];
      mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: minX,
          maxx: maxX,
          X: this.x,
          Y: this.y,
          miny: minY,
          maxy: maxY,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: minYsecondary,
          maxysecondary: maxYsecondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          AxisColor: this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });

      // this.$emit('xCoordsAfterZoom', this.minX, this.maxX);
      // // Clear and redraw the chart with the updated scales
      // this.clearCanvas();
      // this.updateScale();
      // this.data_set.forEach((d) => {
      //   if (d.data?.length > 0) {
      //     let filteredData = d.data.filter(
      //       (element) =>
      //         minX < Number(element.x) &&
      //         Number(element.x) < maxX &&
      //         minY < Number(element.y) &&
      //         maxY > Number(element.y)
      //     );
      //     this.drawLine(filteredData, d.color, d.width, d.lineType);
      //   }
      // });
      // if (this.enableSecondaryYaxis) {
      //   this.data_set_secondary.forEach(async (d) => {
      //     console.log(this.data_set, "when zoomed")
      //     if (d.data?.length > 0) {
      //       let filteredData = d.data.filter(
      //         (element) =>
      //           minX < Number(element.x) &&
      //           Number(element.x) < maxX &&
      //           minYsecondary < Number(element.y) &&
      //           maxYsecondary > Number(element.y)
      //       );
      //       await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
      //     }
      //   });
      // }
      this.isResetZoomBtnEnable = true;
    },
    //   async inItChat() {
    //     this.minX = this.min_X;
    //     this.minY = this.min_Y;
    //     this.minYsecondary = this.min_Y_Secondary;
    //     this.maxX = this.max_X;
    //     this.maxY = this.max_Y;
    //     this.maxYsecondary = this.max_Y_Secondary;
    //     const zoom1 = zoom()
    //       .scaleExtent([1, 50])
    //       .translateExtent([
    //         [this.x, this.y],
    //         [this.width, this.height],
    //       ])
    //       .extent([
    //         [this.x, this.y],
    //         [this.width, this.height],
    //       ])
    //       .on("zoom", this.zoomed);

    //     select(this.cursorCanvas).call(zoom1);

    //     await this.drawXAxis();
    //     await this.drawYAxis();
    //     if (this.enableSecondaryYaxis) {
    //       await this.drawSecondaryYAxis();
    //       this.data_set_secondary.forEach(async (d) => {
    //         if (d.data?.length > 0) {
    //           let filteredData = d.data.filter(
    //             (element) =>
    //               this.minX < Number(element.x) &&
    //               Number(element.x) < this.maxX &&
    //               this.minYsecondary < Number(element.y) &&
    //               this.maxYsecondary > Number(element.y)
    //           );
    //           // await this.drawLine(d.data, d.color, d.width, d.lineType, true);
    //           await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
    //         }
    //       });
    //     }
    //     console.log(this.data_set, "from init function")

    //     this.data_set.forEach(async (d) => {
    //       if (d.data?.length > 0) {
    //         let filteredData = d.data.filter(
    //           (element) =>
    //             this.minX < Number(element.x) &&
    //             Number(element.x) < this.maxX &&
    //             this.minY < Number(element.y) &&
    //             this.maxY > Number(element.y)
    //         );
    //         // await this.drawLine(d.data, d.color, d.width, d.lineType);
    //         await this.drawLine(filteredData, d.color, d.width, d.lineType);
    //       }
    //     });
    //   },

    //   async drawXAxis() {

    //     // const xScaleConfig = {
    //     //   logType: this.logType,
    //     //   minX: this.minX,
    //     //   maxX: this.maxX,
    //     //   x: this.x,
    //     //   width: this.width
    //     // };

    //     // const workerData = {
    //     //   xAxisScaleConfig: xScaleConfig,
    //     //   numXTicks: this.numXTicks
    //     //   // Include other necessary data
    //     // };

    //     // const context = this.context
    //     // console.log(this.offscreenContext, this.offscreenCanvas, 'off screen canvas')

    //     // Create a new instance of the web mse_worker

    //     // Prepare data to send to the mse_worker

    //     // Listen for messages from the mse_worker
    //     // mse_worker.onmessage = (event) => {
    //     //   const tickData = event.data;

    //     //   // Draw X axis based on computed tick data
    //     //   this.drawAxis(context, tickData);

    //     //   // Cleanup: Terminate the mse_worker after use
    //     //   mse_worker.terminate();
    //     // };

    //     // // Send data to the mse_worker for tick computation
    //     // mse_worker.postMessage(workerData);
    //   },

    //   async drawYAxis() {
    //     let context = this.context;
    //     context.save();
    //     context.beginPath();
    //     context.moveTo(this.x, this.y);
    //     context.lineTo(this.x, this.y + this.height);
    //     context.strokeStyle = this.axisColor;
    //     context.lineWidth = 2;
    //     context.stroke();

    //     let yAxis = this.yScale;
    //     if (this.zoomed_Y_Axis) yAxis = this.zoomed_Y_Axis;

    //     const tickValues = yAxis.ticks(this.numYTicks);

    //     tickValues.forEach((tick, i) => {
    //       const yPos = yAxis(tick);
    //       //Draw tick & label
    //       context.beginPath();
    //       context.strokeStyle = this.axisColor;
    //       context.moveTo(this.x - this.tickSize, yPos);
    //       context.lineTo(this.x, yPos);
    //       context.stroke();

    //       context.font = this.font;
    //       context.fillStyle = this.axisColor;
    //       context.textAlign = "right";
    //       context.textBaseline = "middle";
    //       context.fillText(this.formatNumber(tick), this.x - this.padding, yPos);
    //       context.closePath();
    //       //Draw gridlines

    //       context.beginPath();
    //       context.moveTo(this.x, yPos);
    //       context.lineTo(this.x + this.width, yPos);
    //       context.strokeStyle = this.gridColor == "" ? "#ddd" : this.gridColor;
    //       context.lineWidth = 0.5;
    //       context.setLineDash([5, 5]);
    //       context.stroke();
    //       context.closePath();
    //     });
    //     context.fillStyle =
    //       this.primaryAxisLabelColor === ""
    //         ? this.axisColor
    //         : this.primaryAxisLabelColor;

    //     context.rotate(-Math.PI / 2);
    //     context.font = this.yAxisLabelSize
    //       ? `${this.yAxisLabelSize}pt Calibri`
    //       : this.font;
    //     let textWidth = context.measureText((this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()) + `${this.primaryYaxisUnit ? '(' + this.primaryYaxisUnit + ')' : ''}`).width
    //     let titleWithUnit = this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()
    //     context.fillText(`${titleWithUnit} ${this.primaryYaxisUnit ? '(' + this.primaryYaxisUnit + ')' : ''}`,
    //       -this.y - this.height / 2 + textWidth / 2,
    //       this.padding - 5
    //     );
    //     context.restore();
    //   },

    //   async drawAxisY(context, tickValues) {
    //     context.beginPath();
    //     context.moveTo(this.x, this.y);
    //     context.lineTo(this.x, this.y + this.height);
    //     context.strokeStyle = this.axisColor;
    //     context.lineWidth = 2;
    //     context.stroke();

    //     let yAxis = this.yScale;
    //     if (this.zoomed_Y_Axis) yAxis = this.zoomed_Y_Axis;

    //     // const tickValues = yAxis.ticks(this.numYTicks);

    //     tickValues.forEach((tick, i) => {
    //       const yPos = yAxis(tick);
    //       //Draw tick & label
    //       context.beginPath();
    //       context.strokeStyle = this.axisColor;
    //       context.moveTo(this.x - this.tickSize, yPos);
    //       context.lineTo(this.x, yPos);
    //       context.stroke();

    //       context.font = this.font;
    //       context.fillStyle = this.axisColor;
    //       context.textAlign = "right";
    //       context.textBaseline = "middle";
    //       context.fillText(this.formatNumber(tick), this.x - this.padding, yPos);
    //       context.closePath();
    //       //Draw gridlines

    //       context.beginPath();
    //       context.moveTo(this.x, yPos);
    //       context.lineTo(this.x + this.width, yPos);
    //       context.strokeStyle = this.gridColor == "" ? "#ddd" : this.gridColor;
    //       context.lineWidth = 0.5;
    //       context.setLineDash([5, 5]);
    //       context.stroke();
    //       context.closePath();
    //     });
    //     context.fillStyle =
    //       this.primaryAxisLabelColor === ""
    //         ? this.axisColor
    //         : this.primaryAxisLabelColor;

    //     context.rotate(-Math.PI / 2);
    //     context.font = this.yAxisLabelSize
    //       ? `${this.yAxisLabelSize}pt Calibri`
    //       : this.font;
    //     let textWidth = context.measureText((this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()) + `${this.primaryYaxisUnit ? '(' + this.primaryYaxisUnit + ')' : ''}`).width
    //     let titleWithUnit = this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()
    //     context.fillText(`${titleWithUnit} ${this.primaryYaxisUnit ? '(' + this.primaryYaxisUnit + ')' : ''}`,
    //       -this.y - this.height / 2 + textWidth / 2,
    //       this.padding - 5
    //     );
    //     context.restore();

    //   },

    //   async drawSecondaryYAxis() {
    //     const context = this.context;
    //     context.save();
    //     context.beginPath();
    //     context.moveTo(this.width + this.x, this.y);
    //     context.lineTo(this.width + this.x, this.y + this.height);
    //     context.strokeStyle = this.axisColor;
    //     context.lineWidth = 2;
    //     context.stroke();

    //     let secondaryYAxis = this.yScaleSecondary;
    //     if (this.zoomed_secondary_Y_Axis) secondaryYAxis = this.zoomed_secondary_Y_Axis
    //     const secondaryTickValues = secondaryYAxis.ticks(this.numYTicks);

    //     secondaryTickValues.forEach((tick, i) => {
    //       const yPos = secondaryYAxis(tick);
    //       // Draw tick & label for secondary y-axis
    //       context.beginPath();
    //       context.strokeStyle = this.axisColor;
    //       context.moveTo(this.width + this.x, yPos);
    //       context.lineTo(this.width + this.x + this.tickSize, yPos);
    //       context.stroke();

    //       context.font = this.font;
    //       context.fillStyle = this.axisColor;
    //       context.textAlign = "left";
    //       context.textBaseline = "middle";
    //       context.fillText(this.formatNumber(tick), this.width + this.x + this.padding, yPos);
    //       context.closePath();

    //       // Draw gridlines for secondary y-axis
    //       // context.beginPath();
    //       // context.moveTo(this.x, yPos);
    //       // context.lineTo(this.width, yPos);
    //       // context.strokeStyle = "#ddd";
    //       // context.lineWidth = 0.5;
    //       // context.setLineDash([5, 5]);
    //       // context.stroke();
    //       // context.closePath();
    //     });
    //     context.font = this.yAxisLabelSizeSecondary
    //       ? `${this.yAxisLabelSizeSecondary}pt Calibri`
    //       : this.font;
    //     let textWidth = context.measureText((this.secondaryAxixY?.editedName?.toUpperCase() || this.secondaryAxixY?.name?.toUpperCase() || "Secondary Y-Title") + `${this.secondryYaxisUnit ? '(' + this.secondryYaxisUnit + ')' : ''}`).width
    //     context.translate(
    //       this.width + this.x + this.padding + this.paddingFromRight + 5,
    //       this.height / 2 + this.padding + textWidth / 2
    //     );
    //     context.rotate(-Math.PI / 2);
    //     context.fillStyle =
    //       this.secondaryAxisLabelColor == ""
    //         ? this.axisColor
    //         : this.secondaryAxisLabelColor;
    //     let titleWithUnit = this.secondaryAxixY?.editedName?.toUpperCase() || this.secondaryAxixY?.name?.toUpperCase() || "MNEMONIC";
    //     context.fillText(`${titleWithUnit} ${this.secondryYaxisUnit ? '(' + this.secondryYaxisUnit + ')' : ''}`, 0, 0);
    //     context.restore();
    //   },

    //   async drawLine(data, color, width, lineType = null, isSecndary = false) {
    //     let linecolor = color;
    //     if (isSecndary) {
    //       linecolor =
    //         this.secondaryGrapgColor == "" ? linecolor : this.secondaryGrapgColor;
    //     } else {
    //       linecolor =
    //         this.primaryGrapgColor == "" ? linecolor : this.primaryGrapgColor;
    //     }
    //     let context = this.context;
    //     context.save();
    //     context.lineWidth = width;
    //     context.strokeStyle = linecolor;
    //     context.fillStyle = linecolor;
    //     context.beginPath();

    //     let xScale = this.xScale;
    //     let yScale = isSecndary ? this.yScaleSecondary : this.yScale;
    //     if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
    //     if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
    //     if (isSecndary && this.zoomed_secondary_Y_Axis) yScale = this.zoomed_secondary_Y_Axis;

    //     if (data.length > 0) {
    //       // Move to the starting point of the line
    //       const startX = Math.max(0, Math.min(this.width, xScale(data[0].x)));
    //       const startY = Math.max(0, Math.min(this.height, yScale(data[0].y)));
    //       context.moveTo(startX, startY);

    //       if (lineType) context.setLineDash(lineType);

    //       // Draw the line segment by segment
    //       for (var n = 1; n < data.length; n++) {
    //         var point = data[n];
    //         // Ensure the y-coordinate is within the chart area
    //         context.lineTo(xScale(point.x), yScale(point.y));
    //       }
    //     }

    //     // Stroke and close the path
    //     context.stroke();
    //     context.closePath();

    //     context.restore();
    //     context.setLineDash([]);
    //   },

    //   async updateScale() {
    //     this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    //     await this.drawXAxis();
    //     await this.drawYAxis();
    //     if (this.enableSecondaryYaxis) {
    //       await this.drawSecondaryYAxis();
    //     }
    //   },

    //   handleSelectionStart(event) {
    //     if (!this.enableZoom) return;
    //     // Track the starting point of the mouse drag
    //     const rect = this.canvas.getBoundingClientRect();
    //     this.selectionStart = {
    //       x: event.clientX - rect.left,
    //       y: event.clientY - rect.top,
    //     };
    //     this.selectionEnd = {
    //       x: event.clientX - rect.left,
    //       y: event.clientY - rect.top,
    //     };
    //     this.isSelecting = true;
    //   },
    handleSelectionMove(event) {
      this.handleMouseMoveForTooltip(event);
      // if (!this.enableZoom) {
      //   this.handleMouseMoveForTooltip(event);
      //   return;
      // }
      // if (this.isSelecting) {
      //   // Track the current mouse position during the drag
      //   const rect = this.canvas.getBoundingClientRect();
      //   this.selectionEnd = {
      //     x: event.clientX - rect.left,
      //     y: event.clientY - rect.top,
      //   };
      //   this.drawSelectionRectangle();
      // }
    },
    //   handleSelectionEnd() {
    //     // Calculate the zoom area based on the selected rectangle
    //     const selectedArea = {
    //       minX: Math.min(
    //         this.selectionStart.x + this.x,
    //         this.selectionEnd.x + this.x
    //       ),
    //       minY: Math.max(
    //         this.selectionStart.y + this.y,
    //         this.selectionEnd.y + this.y
    //       ),
    //       maxX: Math.max(
    //         this.selectionStart.x + this.x,
    //         this.selectionEnd.x + this.x
    //       ),
    //       maxY: Math.min(
    //         this.selectionStart.y + this.y,
    //         this.selectionEnd.y + this.y
    //       ),
    //     };
    //     console.log("selectedArea", selectedArea);
    //     // Calculate the new scales based on the selected area
    //     const newMinX = this.xScale.invert(selectedArea.minX);
    //     const newMaxX = this.xScale.invert(selectedArea.maxX);
    //     const newMinY = this.yScale.invert(selectedArea.maxY);
    //     const newMaxY = this.yScale.invert(selectedArea.minY);
    //     const newMinYsecondary = this.yScaleSecondary.invert(selectedArea.maxY);
    //     const newMaxYsecondary = this.yScaleSecondary.invert(selectedArea.minY);

    //     // Update the scales
    //     this.minX = newMinX;
    //     this.maxX = newMaxX;
    //     this.minY = newMinY;
    //     this.maxY = newMaxY;
    //     this.$emit('xCoordsAfterZoom', this.minX, this.maxX);
    //     if (this.enableSecondaryYaxis) {
    //       this.minYsecondary = newMinYsecondary;
    //       this.maxYsecondary = newMaxYsecondary;
    //     }
    //     // Clear and redraw the chart with the updated scales
    //     this.clearCanvas();
    //     this.updateScale();
    //     this.data_set.forEach((d) => {
    //       if (d.data?.length > 0) {
    //         let filteredData = d.data.filter(
    //           (element) =>
    //             this.minX < Number(element.x) &&
    //             Number(element.x) < this.maxX &&
    //             this.minY < Number(element.y) &&
    //             this.maxY > Number(element.y)
    //         );
    //         this.drawLine(filteredData, d.color, d.width);
    //       }
    //     });
    //     if (this.enableSecondaryYaxis) {
    //       this.data_set_secondary.forEach((d) => {
    //         if (d.data?.length > 0) {
    //           let filteredData = d.data.filter(
    //             (element) =>
    //               this.minX < Number(element.x) &&
    //               Number(element.x) < this.maxX &&
    //               this.minYsecondary < Number(element.y) &&
    //               this.maxYsecondary > Number(element.y)
    //           );
    //           this.drawLine(filteredData, d.color, d.width, d.lineType, true);
    //         }
    //       });
    //     }
    //   },

    //   drawSelectionRectangle() {
    //     // Draw the selection rectangle on the canvas
    //     const context = this.context;
    //     context.fillStyle = "rgba(0, 0, 255, 0.2)";
    //     context.fillRect(
    //       this.selectionStart.x,
    //       this.selectionStart.y,
    //       this.selectionEnd.x - this.selectionStart.x,
    //       this.selectionEnd.y - this.selectionStart.y
    //     );
    //   },

    //   clearCanvas() {
    //     this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    //   },
    //

    //     // Redraw the chart with the updated scales
    //     await this.updateScale();
    //     this.data_set.forEach(async (d) => {
    //       if (d.data?.length > 0) {
    //         let filteredData = d.data.filter(
    //           (element) =>
    //             this.minX < Number(element.x) &&
    //             Number(element.x) < this.maxX &&
    //             this.minY < Number(element.y) &&
    //             this.maxY > Number(element.y)
    //         );
    //         await this.drawLine(filteredData, d.color, d.width, d.lineType);
    //       }
    //     });
    //     if (this.enableSecondaryYaxis) {
    //       this.data_set_secondary.forEach(async (d) => {
    //         if (d.data?.length > 0) {
    //           let filteredData = d.data.filter(
    //             (element) =>
    //               this.minX < Number(element.x) &&
    //               Number(element.x) < this.maxX &&
    //               this.minYsecondary < Number(element.y) &&
    //               this.maxYsecondary > Number(element.y)
    //           );
    //           // await this.drawLine(d.data, d.color, d.width, d.lineType, true);
    //           await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
    //         }
    //       });
    //     }
    //   },

    handleMouseMoveForTooltip(event) {
      const rect = this.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // Perform logic to find the data point corresponding to the mouse coordinates
      const dataPoint = this.findDataPoint(mouseX, mouseY);

      if (dataPoint) {
        // Use transformed coordinates for tooltip positioning
        const transformedCoordinates = this.transformContextForMouseCoords(
          mouseX,
          mouseY
        );

        // Update tooltip position and content
        this.tooltip = {
          left: transformedCoordinates.x + 10,
          top: this.canvas.height - transformedCoordinates.y - 10, // Adjust for bottom to top display
          content: `${dataPoint.name ? dataPoint.name : "X"
            }: ${dataPoint.x.toFixed(2)}, MD: ${dataPoint.y.toFixed(2)}`,
        };
        console.log("tooltip after finding the pont", this.tooltip);
      } else {
        // Hide tooltip if no data point is found
        this.tooltip = null;
      }
    },

    transformContextForMouseCoords(mouseX, mouseY) {
      const context = this.context;
      context.save();
      context.translate(this.x, this.y + this.height);
      context.scale(1, -1); // Flip the Y-axis scaling factor
      const transformedX = mouseX - this.x;
      const transformedY = this.height - (mouseY - this.y);
      context.restore();

      return { x: transformedX, y: transformedY };
    },

    findDataPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates

      for (const dataset of this.data_set) {
        for (const dataPoint of dataset.data) {
          // console.log(dataPoint,'all datapoint in loop')
          let xScale = this.xScale;
          let yScale = this.yScale;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);

          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            console.log(dataPoint, "finddatapoint function");
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    findSecondaryPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates
      for (const dataset of this.data_set_secondary) {
        for (const dataPoint of dataset.data) {
          let xScale = this.xScale;
          let yScale = this.yScaleSecondary;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_secondary_Y_Axis)
            yScale = this.zoomed_secondary_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);
          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    //   formatNumber(number) {
    //     const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
    //     let suffixIndex = 0;

    //     while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    //       number /= 1000;
    //       suffixIndex++;
    //     }

    //     return number.toFixed(1) + suffixes[suffixIndex];
    //   },
    crossHairMousemove(event) {
      const rect = this.cursorCanvas.getBoundingClientRect();
      const x = event.pageX - rect.left;
      const y = event.pageY - rect.top;
      this.xCoord = event.pageX;
      this.yCoord = event.pageY;
      if (this.enableTooltip) {
        this.cursorContext.clearRect(
          0,
          0,
          this.cursorCanvas.width,
          this.cursorCanvas.height
        );
        this.cursorContext.beginPath();
        this.cursorContext.moveTo(0, y);
        this.cursorContext.lineTo(this.width, y);
        this.cursorContext.moveTo(x, 0);
        this.cursorContext.lineTo(x, this.width);
        this.cursorContext.strokeStyle = "#ddd";
        this.cursorContext.stroke();
        this.cursorContext.closePath();
        let dataPoint =
          this.findDataPoint(x + this.x, y + this.y) ||
          this.findSecondaryPoint(x + this.x, y + this.y);
        if (dataPoint) {
          // Use transformed coordinates for tooltip positioning
          // const transformedCoordinates = this.transformContextForMouseCoords(
          //   x + 20,
          //   y + 20
          // );

          // moment(dataPoint.x).format("MM-DD-YYYY HH:mm:ss")

          // Update tooltip position and content
          this.tooltip = {
            left: x,
            top: y, // Adjust for bottom to top display
            content: `${this.logType}: ${this.logType == "time"
              ? moment(new Date(dataPoint.x).toLocaleString("en-US")).format(
                "MM-DD-YYYY HH:mm:ss"
              )
                : dataPoint.x
          }, ${ dataPoint.name ? dataPoint.name : "Y" }: ${
            dataPoint.y.toFixed(
              2
            )
          } `,
          };
          let secondaryDataPoint = this.findSecondaryPoint(
            x + this.x,
            y + this.y
          );
          if (secondaryDataPoint && dataPoint.name != secondaryDataPoint.name) {
            this.tooltip.content += `, ${
            secondaryDataPoint.name ? secondaryDataPoint.name : "Y"
          }: ${ secondaryDataPoint.y.toFixed(2) } `;
          }
        } else {
          // Hide tooltip if no data point is found
          this.tooltip = null;
        }
      }
      if (this.mousedown && this.enableZoom) {
        this.cursorContext.clearRect(
          0,
          0,
          this.cursorCanvas.width,
          this.cursorCanvas.height
        );
        this.cursorContext.beginPath();
        var width = x - this.selectionStart.x;
        var height = y - this.selectionStart.y;
        this.cursorContext.rect(
          this.selectionStart.x,
          this.selectionStart.y,
          width,
          height
        );
        this.cursorContext.fillStyle = "#8ED6FF";
        this.cursorContext.globalAlpha = 0.5;
        this.cursorContext.fill();
        this.cursorContext.lineWidth = 1;
        this.cursorContext.strokeStyle = "#ddd";
        this.cursorContext.stroke();
        this.selectionEnd.x = x;
        this.selectionEnd.y = y;
        this.cursorContext.globalAlpha = 1;
        this.isDragged = true;
      }
    },
    crossHairMouseLeave() {
      this.xCoord = 0;
      this.yCoord = 0;
      if (!this.enableTooltip) return;
      this.tooltip = null;
      this.selectionEnd.x = 0;
      this.selectionEnd.y = 0;
      this.selectionStart.x = 0;
      this.selectionStart.y = 0;
      this.cursorContext.clearRect(
        0,
        0,
        this.cursorCanvas.width,
        this.cursorCanvas.height
      );
      this.isDragged = false;
    },
  },
  mounted() {
    // Assuming this.canvas and this.cursorCanvas are defined in the template as <canvas ref="yourCanvasId"></canvas>
    // this.canvas = this.$refs[this.id];
    // this.context = this.canvas.getContext("2d");
    this.cursorCanvas = this.$refs.cursorCanvas;
    this.cursorContext = this.cursorCanvas.getContext("2d");
    this.canvas = this.$refs[this.id];
    if (!mse_worker) {
      mse_worker = new Worker();
    }

    setTimeout(() => {
    console.log('coming to mounted evrytime')
    moment.tz.setDefault(this.time_zone);
      // Calculate dimensions for OffscreenCanvas

      this.width =
        this.canvas.width - this.x - this.padding * 2 - this.paddingFromRight;
      this.height =
        this.canvas.height - this.y - this.padding * 3 - this.fontHeight;
      console.log(
        "coming to mounted of canvasmsechatcopy",
        this.canvas.width,
        this.width,
        this.height
      );
      const zoom1 = zoom()
        .scaleExtent([1, 50])
        .translateExtent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .extent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .on("zoom", this.zoomed);

      select(this.cursorCanvas).call(zoom1);

      this.offscreen = this.canvas.transferControlToOffscreen();

      mse_worker.postMessage(
        {
          values: {
            YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
            XAxisLabelSize: this.xAxisLabelSize,
            YAxisLabelSize: this.yAxisLabelSize,
            PrimaryGrapgColor: this.primaryGrapgColor,
            SecondaryGrapgColor: this.secondaryGrapgColor,
            PrimaryAxisLabelColor: this.primaryAxisLabelColor,
            SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
            GridColor: this.gridColor,
            SecondryYaxisUnit: this.secondryYaxisUnit,
            minx: this.min_X,
            maxx: this.max_X,
            X: this.x,
            Y: this.y,
            miny: this.min_Y,
            maxy: this.max_Y,
            log: this.logType,
            Width: this.totl_width,
            Height: this.totl_height,
            EnableSecondaryYaxis: this.enableSecondaryYaxis,
            minysecondary: this.min_Y_Secondary,
            maxysecondary: this.max_Y_Secondary,
            PrimaryYaxisUnit: this.primaryYaxisUnit,
            Time_zone: this.time_zone,
            AxisColor : this.axisColor
          },
          canvas: this.offscreen,
          type: "init",
          graphData: JSON.stringify(this.data_set),
          Data_set_secondary: JSON.stringify(this.data_set_secondary),
          secondaryAxixY: JSON.stringify(this.secondaryAxixY),
          secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        },
        [this.offscreen]
      );
      console.log("coming to mounted of canvaschat");

      // Optional: Handle errors from the web mse_worker
      mse_worker.onerror = (error) => {
        console.error("Error in web mse_worker:", error);
      };
    }, 100);
  },

  watch: {
    async xAxisLabelSize() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async yAxisLabelSizeSecondary() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },

    async yAxisLabelSize() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async primaryGrapgColor() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async secondaryGrapgColor() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async primaryAxisLabelColor() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async secondaryAxisLabelColor() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    async gridColor() {
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
    // async totl_width(val) {
    //   console.log('coming to chnage width');
    //   console.log(this.width, 'before width ')

    //     await mse_worker.postMessage(
    //       { values: { minx: this.minX, maxx: this.maxX, X: this.x, Y: this.y, miny: this.minY, maxy: this.maxY, log: this.logType, Width: val, Height: this.totl_height }, type: 'update', graphData: JSON.stringify(this.data_set) }

    //     );

    // },
    // async totl_height(val) {
    //   //   this.width = this.canvas.width - this.x - this.padding * 2 - this.paddingFromRight;
    //   // this.height = this.canvas.height - this.y - this.padding * 3 - this.fontHeight;

    //     // if(this.maxX && this.maxy){

    //     await mse_worker.postMessage(
    //       { values: { minx: this.minX, maxx: this.maxX, X: this.x, Y: this.y, miny: this.minY, maxy: this.maxY, log: this.logType, Width: this.totl_width, Height: val }, type: 'update', graphData: JSON.stringify(this.data_set) }

    //     );
    //   // this.refreshcanvas +=12

    // },

    data_set: {
      async handler(newValue, oldValue) {
        console.log(this.minX, this.maxX, "min and max values");
        await mse_worker.postMessage({
          values: {
            YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
            XAxisLabelSize: this.xAxisLabelSize,
            YAxisLabelSize: this.yAxisLabelSize,
            PrimaryGrapgColor: this.primaryGrapgColor,
            SecondaryGrapgColor: this.secondaryGrapgColor,
            PrimaryAxisLabelColor: this.primaryAxisLabelColor,
            SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
            GridColor: this.gridColor,
            SecondryYaxisUnit: this.secondryYaxisUnit,
            minx: this.min_X,
            maxx: this.max_X,
            X: this.x,
            Y: this.y,
            miny: this.min_Y,
            maxy: this.max_Y,
            log: this.logType,
            Width: this.totl_width,
            Height: this.totl_height,
            EnableSecondaryYaxis: this.enableSecondaryYaxis,
            minysecondary: this.min_Y_Secondary,
            maxysecondary: this.max_Y_Secondary,
            PrimaryYaxisUnit: this.primaryYaxisUnit,
            Time_zone: this.time_zone,
            AxisColor : this.axisColor
          },
          type: "update",
          graphData: JSON.stringify(this.data_set),
          Data_set_secondary: JSON.stringify(this.data_set_secondary),
          secondaryAxixY: JSON.stringify(this.secondaryAxixY),
          secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        });

        // await newValue.forEach(async (d) => {
        //   if (d.data?.length > 0) await this.updateScale();
        //   await this.drawLine(d.data, d.color, d.width, d.lineType);
        // });
        // if (this.enableSecondaryYaxis) {
        //   await this.data_set_secondary.forEach(async (d) => {
        //     if (d.data?.length > 0)
        //       await this.drawLine(d.data, d.color, d.width, d.lineType, true);
        //   });
        // }
        // await this.crossHairMousemove({ pageX: this.xCoord, pageY: this.yCoord });
      },
      deep: true,
    },
    // data_set_secondary: {
    //   async handler(newValue, oldValue) {
    //     // await newValue.forEach(async (d) => {
    //     //   if (d.data?.length > 0) await this.updateScale();
    //     //   await this.drawLine(d.data, d.color, d.width, d.lineType, true);
    //     // });
    //     // await this.data_set.forEach(async (d) => {
    //     //   if (d.data?.length > 0)
    //     //     await this.drawLine(d.data, d.color, d.width, d.lineType);
    //     // });
    //     // await this.crossHairMousemove({ pageX: this.xCoord, pageY: this.yCoord });
    //   },
    //   deep: true,
    // },
    async min_X(val) {
      console.log("coming to minx", val, this.minX);
      this.minX = val;
      console.log("coming to minx after", val, this.minX);

      if (this.maxX > val) {
        await mse_worker.postMessage({
          values: {
            YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
            XAxisLabelSize: this.xAxisLabelSize,
            YAxisLabelSize: this.yAxisLabelSize,
            PrimaryGrapgColor: this.primaryGrapgColor,
            SecondaryGrapgColor: this.secondaryGrapgColor,
            PrimaryAxisLabelColor: this.primaryAxisLabelColor,
            SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
            GridColor: this.gridColor,
            SecondryYaxisUnit: this.secondryYaxisUnit,
            minx: this.min_X,
            maxx: this.max_X,
            X: this.x,
            Y: this.y,
            miny: this.min_Y,
            maxy: this.max_Y,
            log: this.logType,
            Width: this.totl_width,
            Height: this.totl_height,
            EnableSecondaryYaxis: this.enableSecondaryYaxis,
            minysecondary: this.min_Y_Secondary,
            maxysecondary: this.max_Y_Secondary,
            PrimaryYaxisUnit: this.primaryYaxisUnit,
            Time_zone: this.time_zone,
            AxisColor : this.axisColor
          },
          type: "update",
          graphData: JSON.stringify(this.data_set),
          Data_set_secondary: JSON.stringify(this.data_set_secondary),
          secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        });
        console.log("coming after postmessage");
      }
    },
    async min_Y(val) {
      this.minY = val;
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
      // this.updateScale();
    },
    async min_Y_Secondary(val) {
      this.minYsecondary = val;
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
      // this.updateScale();
    },
    max_X(val) {
      this.maxX = val;
      // this.canvas = this.$refs['webcanvas']

      // this.width = this.canvas.width - this.x - this.padding * 2 - this.paddingFromRight;
      // this.height = this.canvas.height - this.y - this.padding * 3 - this.fontHeight;

      // this.offscreen = this.canvas.transferControlToOffscreen();

      mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });

      // this.updateScale();
    },
    async max_Y(val) {
      this.maxY = val;
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
      // this.updateScale();
    },
    async max_Y_Secondary(val) {
      this.maxYsecondary = val;
      await mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: JSON.stringify(this.data_set_secondary),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
      // this.updateScale();
    },
    async logType(val) {
      // await this.updateScale();
      this.xAxisTitle = val;
    },
    primaryAxixY(val) {
      if (val) {
        this.yAxisTitle = val.name;
      }
    },
    async enableSecondaryYaxis() {
      console.log("triggering secondary axis");
      mse_worker.postMessage({
        values: {
          YAxisLabelSizeSecondary: this.yAxisLabelSizeSecondary,
          XAxisLabelSize: this.xAxisLabelSize,
          YAxisLabelSize: this.yAxisLabelSize,
          PrimaryGrapgColor: this.primaryGrapgColor,
          SecondaryGrapgColor: this.secondaryGrapgColor,
          PrimaryAxisLabelColor: this.primaryAxisLabelColor,
          SecondaryAxisLabelColor: this.secondaryAxisLabelColor,
          GridColor: this.gridColor,
          SecondryYaxisUnit: this.secondryYaxisUnit,
          minx: this.min_X,
          maxx: this.max_X,
          X: this.x,
          Y: this.y,
          miny: this.min_Y,
          maxy: this.max_Y,
          log: this.logType,
          Width: this.totl_width,
          Height: this.totl_height,
          EnableSecondaryYaxis: this.enableSecondaryYaxis,
          minysecondary: this.min_Y_Secondary,
          maxysecondary: this.max_Y_Secondary,
          PrimaryYaxisUnit: this.primaryYaxisUnit,
          Time_zone: this.time_zone,
          AxisColor : this.axisColor
        },
        type: "update",
        graphData: JSON.stringify(this.data_set),
        Data_set_secondary: [],
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
        secondaryAxixY: JSON.stringify(this.secondaryAxixY),
      });
    },
  },
  beforeUnmount() {
    if (mse_worker) {
      mse_worker.terminate();
      mse_worker = null;
    }
    console.log("coming to web workers termintae");
  },
  expose: ["resetZoom"],
};
</script>
<style scoped>
.zoombtn {
  margin-top: 5px;
  font-size: 10px;
}

.tooltip {
  position: absolute;
  background-color: rgba(49, 46, 46, 0.8);
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  pointer-events: none;
  z-index: 9999999;

  font-size: 15px;
  min-width: 220px;
  margin-top: 100px;
}

.cursor_can {
  position: absolute;
  z-index: 999999;
}
</style>
