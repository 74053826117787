import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    //dexponent
    dexponentListSave: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.reports.DEXPONENT_DATA_LIST_SAVE(), data);
            return response;
        } catch (error) {

            return error;
        }
    },
    dexponentSearch: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.reports.DEXPONENT_DATA_SEARCH(), data);
            return response;
        } catch (error) {

            return error;
        }
    },
    dexponentDelete: async (data) => {
        try {
            let response = await Axios.delete(constant.DATASERVICES_URL + api.reports.DEXPONENT_DELETE(), data);
            return response;
        } catch (error) {

            return error;
        }
    },

    

    
}