<template>
  <div @contextmenu="this.setContextIdWidget" class="ReportsMain">
    <section>
      <div>
        <ReportsTopBar :onToggleSideBar="toggleLeftBar" :show_left_bar="show_left_bar" :displayId="displayId"
          :widgetId="widgetId" />
      </div>
      <div class="flex">
        <Reports_sidebar v-show="show_left_bar" :displayId="displayId" :widgetId="widgetId" :widgetIndex="widgetIndex"
          :displayIndex="displayIndex" :height="height - 20" :idx="idx" />
        <div class="relative">
          <div id="printableArea" :style="{ left: show_left_bar ? '10rem' : '0' }" v-bind:class="{
    content: true,
    hidden: selectedReport ? false : true,
  }">
            <div class="container2">
              <ReportsTrend :values="values" @emitMaindash="emitMaindash" ref="ReportTrend" class="plot"
                :id="widgetId" :trendIndex="0" :height="height - 20"
                :width="show_left_bar ? width - 160 : parseInt(width)" :style="{
    width: show_left_bar
      ? width - 160 + 'px'
      : parseInt(width) + 'px',
    height: height - 20 + 'px',
  }" :displayId="displayId" :widgetId="widgetId" :displayIndex="displayIndex" :widgetIndex="widgetIndex"
                :well_id="wellId" :wellDetails="wellDetails" :idx="idx" :selectedReport="selectedReport" />
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import ReportsTopBar from "../../Navbars/ReportsTopNavBar.vue";
import Reports_sidebar from "../../Sidebar/Reports_sidebar.vue";
import ReportsTrend from "./ReportsTrend.vue";
export default {

  name: "Reports",
  emits: ['openprop'],
  data() {
    return {
      idx: null,
      wellId: null,
      wellboreId: null,
      logId: null,
      status: null,
      logType: null,
      logTypeId: null,
      wellDetails: {},
    };
  },
  props: {

    widgetId: String,
    height: [String, Number],
    width: [String, Number],
    top: [String, Number],
    left: [String, Number],
    displayId: [String, Number],
    key: [String, Number],
    widgetIndex: [String, Number],
    displayIndex: [String, Number],
    parentW: [Number, String],
    parentH: [Number, String],
    values: Array
  },
  components: {
    Reports_sidebar,
    ReportsTopBar,
    ReportsTrend,
  },
  watch: {
    displayIndex(val) {
      try {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.idx = i;
              break;
            }
          }
        }
      }
      catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    show_left_bar() {
      if (Object.keys(this.$store.state.rect.rects[this.idx]).includes('show_left_bar')) {
        return this.$store.state.rect.rects[this.idx].show_left_bar
      }
      else {
        return false;
      }
    },
    selectedReport() {
      if (Object.keys(this.$store.state.rect.rects[this.idx]).includes('selectedReport')) {
        return this.$store.state.rect.rects[this.idx].selectedReport
      }
      else {
        return null;
      }
    }
  },
  beforeUnmount() {
  },
  beforeMount() {
    try {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          )
            this.idx = i;
        }
      }
    } catch (err) {
      console.error(err);
    }
  },
  async mounted() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    let apollodart = this.$serviceHelpers.getDetails("apollodart");
    if (apollodart?.user) {
      this.loggedInUser = apollodart.user?.user_name;
    }

    if (displayDtls) {
      this.wellDetails = displayDtls;
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;

    }
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display
      ) {
        console.log("mutation.payload ", mutation.payload);
        this.wellDetails = mutation.payload;
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellBoreName;
        this.logId = mutation.payload.logId;
        this.status = mutation.payload.wellStatus;
        this.logType = mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
      }
    });
  },
  methods: {
    emitMaindash(index, val) {
      this.$emit('openprop', index, val)
      console.log("emiting working", index, val)

    },
    onClickOfReports(index, config) {

      console.log('this.$store.state.rect.selectedWidgetProps', this.$store.state.rect)
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: index,
        value: config,
      });
      console.log(this.$store.state.rect.toggle_right_Sidebar, "toggle")
    },
    openProperties(index, rect) {
      console.log('triggering')
      console.log(index, rect, "from value")

    },
    async commonDispatcher(val, key) {
      await this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: val,
        field: key,
      });
    },
    async toggleLeftBar() {
      if (this.wellId && this.wellboreId) {
        this.show_left_bar = !this.show_left_bar;
        if (Object.keys(this.$store.state.rect.rects[this.idx]).includes('show_left_bar')) {
          await this.commonDispatcher(!this.$store.state.rect.rects[this.idx].show_left_bar, 'show_left_bar');
        }
        else {
          await this.commonDispatcher(true, 'show_left_bar');
        }
      } else {
        this.$toast.clear();
        this.$toast.info(`Please select the well`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
  },
};
</script>
<style scopped src="../../../assets/styles/leftbar_styles.css"></style>